<template>
  <div>
    <div
      class="bg-white mb-1 p-1"
      style="border-radius: 5px"
    >
      <div>
        <h3 class="font-weight-bolder text-body">
          {{ $t(ORDER_INFORMATION_TITLE) }}
        </h3>
        <span class="inputLabel">{{ customerInformation['customer_id'].label }}</span>
        <b-skeleton
          height="40px"
          style="border-radius: 5px"
        />
      </div>
      <b-row>
        <b-col
          cols="6"
        >
          <div
            v-for="field in ['required_by', 'billing_contact', 'billing_phone', 'billing_email', 'billing_address', 'payment_term_id']"
            :key="field"
            class="mt-2"
            style="height: 70px"
          >
            <span class="inputLabel">{{ customerInformation[field].label }}</span>
            <b-skeleton
              height="40px"
              style="border-radius: 5px"
            />

          </div>
        </b-col>
        <b-col
          cols="6"
        >
          <div
            v-for="field in ['attachments', 'internal_account_notes', 'internal_order_reference', 'external_order_notes']"
            :key="field"
            class="mt-2"
            style="height: 100px"
          >
            <span class="inputLabel">{{ customerInformation[field].label }}</span>
            <b-skeleton
              height="70px"
              style="border-radius: 5px"
            />

          </div>
        </b-col>
      </b-row>
    </div>
    <div
      class="bg-white mb-1 p-1"
      style="border-radius: 5px"
    >
      <div>
        <h3 class="font-weight-bolder text-body">
          {{ $t(DISPATCH_TITLE) }}
        </h3>
      </div>
      <b-row>
        <b-col
          v-for="field in ['receiving_warehouse', 'dispatch_contact', 'receive_method', 'external_shipping_notes']"
          :key="field"
          cols="6"
        >
          <div
            class="mt-2"
            style="height: 70px"
          >
            <span class="inputLabel">{{ dispatch[field].label }}</span>
            <b-skeleton
              height="40px"
              style="border-radius: 5px"
            />
          </div>
        </b-col>
      </b-row>
    </div>
    <div
      class="bg-white mb-1 p-1"
      style="border-radius: 5px"
    >
      <h3 class="font-weight-bolder text-body">
        {{ $t(STOCK_ITEMS_TITLE) }}
      </h3>
      <b-skeleton
        height="40px"
        style="border-radius: 5px"
      />
    </div>
    <div
      class="bg-white mb-1 p-1"
      style="border-radius: 5px"
    >
      <h3 class="font-weight-bolder text-body">
        {{ $t(OTHER_CHARGES_OR_ITEMS_TITLE) }}
      </h3>
      <b-skeleton
        height="40px"
        style="border-radius: 5px"
      />
    </div>
  </div>
</template>

<script>
import { BSkeleton, BRow, BCol } from 'bootstrap-vue'
import config from '../../create/direct-request-for-quote/config'

export default {
  name: 'Skeleton',
  components: {
    BRow,
    BCol,
    BSkeleton,
  },
  setup() {
    const {
      dispatch,
      DISPATCH_TITLE,
      STOCK_ITEMS_TITLE,
      customerInformation,
      ORDER_INFORMATION_TITLE,
      OTHER_CHARGES_OR_ITEMS_TITLE,
    } = config()

    return {
      dispatch,
      DISPATCH_TITLE,
      STOCK_ITEMS_TITLE,
      customerInformation,
      ORDER_INFORMATION_TITLE,
      OTHER_CHARGES_OR_ITEMS_TITLE,
    }
  },
}
</script>
