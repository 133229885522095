<template>
  <div>
    <portal
      to="body-top"
      class="mb-2"
    >
      <b-skeleton-wrapper
        :loading="isFormLoading"
        class="d-flex justify-content-center"
      >
        <template #loading>
          <b-skeleton
            width="30%"
            height="100%"
            class="m-0"
          />
        </template>
        <h3 class="text-center font-weight-bolder mb-1">
          {{ titleOfDraft }}
        </h3>
      </b-skeleton-wrapper>
      <quote-status-bar
        :quote-status-bar="quoteStatusBar"
        :item="itemOrder"
      />
    </portal>
    <error-alert
      :fields="{}"
      :error="onError"
    />
    <skeleton v-if="isFormLoading" />
    <router-view v-if="!isFormLoading && itemOrder" />
  </div>
</template>

<script>
import QuoteStatusBar from '@/views/main/orders/components/use-as-global/QuoteStatusBar.vue'
import { BSkeleton, BSkeletonWrapper } from 'bootstrap-vue'
import Skeleton from '@/views/main/orders/view/purchase/components/update/direct-request-for-quote/Skeleton.vue'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import config from '../config'

export default {
  name: 'UpdateForm',
  components: {
    ErrorAlert,
    Skeleton,
    BSkeleton,
    BSkeletonWrapper,
    QuoteStatusBar,
  },
  data() {
    return {}
  },
  computed: {
    itemOrder() {
      return this.$store.state[this.MODULE_NAME].order
    },
    titleOfDraft() {
      return `Quote ${this.itemOrder?.order_no || '-'} — ${
          this.itemOrder?.customer?.cust_name_dba || '-'
      }`
    },
    isFormLoading() {
      return this.$store.state[this.MODULE_NAME].isLoading
    },
    onError() {
      return this.$store.state[this.MODULE_NAME].onError
    },
  },
  created() {
    this.getSubRentItem(this)
  },
  methods: {},
  setup() {
    const {
      quoteStatusBar, MODULE_NAME,
      customerInformation,
      dispatchAndReturnFields,
      periodFields,
      getSubRentItem,
    } = config()

    return {
      MODULE_NAME,
      quoteStatusBar,
      customerInformation,
      dispatchAndReturnFields,
      periodFields,
      getSubRentItem,
    }
  },
}
</script>
